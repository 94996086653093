@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

body {
    font-family: 'Nunito Sans' !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* navbar-layout */
.navbar-layout {
    background-color: var(--bs-white);
    box-shadow: var(--bs-box-shadow-sm);
    position: sticky;
    z-index: 100;
    top: 0;
}

.navbar-layout .navbar .navbar-brand img {
    height: 5rem;
}

.navbar-layout .navbar .navbar-nav .nav-link {
    font-weight: 600;
    color: var(--bs-dark);
    text-transform: uppercase;
    font-size: 14px;
}

.navbar-layout .navbar .navbar-nav .nav-link:hover {
    color: var(--bs-primary);
}

.navbar-layout .navbar .navbar-nav {
    gap: 1rem;
}

/* slider */
.header-slider .carousel .carousel-inner .carousel-item .carousel-caption,
.header-slider .carousel .carousel-indicators {
    z-index: 100;
}

.header-slider .carousel .carousel-inner {
    height: 75vh;
}

.header-slider .carousel .carousel-inner .carousel-item,
.header-slider .carousel .carousel-inner .carousel-item img {
    height: 100%;
}

.header-slider .carousel .carousel-inner .carousel-item img {
    object-fit: cover;
}

.header-slider .carousel .carousel-inner::before {
    content: '';
    position: absolute;
    background-color: rgb(3 5 19 / 60%);
    width: 100%;
    height: 100%;
    z-index: 10;
}

.header-slider .carousel .carousel-inner .carousel-item .carousel-caption {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    min-width: 60%;
}

.header-slider .carousel .carousel-inner .carousel-item .carousel-caption h1 {
    font-weight: 800;
    margin-bottom: 1rem;
}

.header-slider .carousel .carousel-inner .carousel-item .carousel-caption p {
    margin-bottom: 0;
}

.header-slider .carousel .carousel-indicators {
    bottom: 10%;
    margin-bottom: 0;
}

/* search-bar */
.search-bar {
    margin-top: -3rem;
    z-index: 10;
    position: relative;
}

.search-bar form {
    background-color: var(--bs-white);
    box-shadow: 0px 10px 33px -27px rgba(0, 0, 0, 0.4);
}

.search-bar form .form-group {
    padding: 1rem;
    border-right: 1px solid var(--bs-border-color);
    width: 100%;
}

.search-bar form .form-group .btn {
    width: 100%;
    border-radius: 0;
    font-weight: 600;
}

.search-bar form .col:last-of-type .form-group {
    padding: 0;
    border: 0;
    display: flex;
}

.search-bar form .form-group .form-select {
    text-transform: capitalize;
}

/* oxfor-categories */
.oxfor-categories {
    text-align: center;
    margin: 3rem 0 2rem 0;
}

.heading-section {
    margin-bottom: 1.50rem;
    width: 100%;
}

.heading-section .subheading {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--bs-success);
}

.heading-section .heading-title {
    font-weight: 700;
}

.heading-section .heading-description {
    color: var(--bs-secondary);
}

.heading-section .heading-description:last-of-type {
    margin-bottom: 0;
}

.oxfor-categories a {
    padding: 1.50rem;
    background-color: #f3f5f1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: 0.25rem;
    transition: all .4s ease-in-out;
    font-weight: 600;
    font-size: 20px;
    color: var(--bs-dark);
}

.oxfor-categories a i {
    font-size: 3rem;
}

.oxfor-categories a:hover {
    background-color: var(--bs-success);
    color: var(--bs-white);
}

/* .our-properties  */
.our-properties .heading-section {
    text-align: center;
}

.our-properties .card .card-body .location {
    /* font-size: 14px; */
    display: flex;
    align-items: center;
    gap: 0.50rem;
    margin-bottom: 0.50rem;
}

.our-properties .card .card-body .location i {
    color: var(--bs-success);
    font-weight: 600;
    font-size: 18px;
}

.our-properties .card .card-body .location .badge {
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 2px;
}

.our-properties .card .card-body .location .location-pin {
    color: var(--bs-secondary);
}

.our-properties .card {
    box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
    border: 0;
    overflow: hidden;
    width: 100%;
}

.our-properties .card,
.our-properties .card .card-img-top {
    border-radius: 0;
    transition: all 0.3s ease;
}

.our-properties .card .card-img-top {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.our-properties .card .propertie-card-img {
    position: relative;
    overflow: hidden;
    height: 20rem;
}

.our-properties .card .propertie-card-img:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .3;
    background: #000000;
    z-index: 1;
    transition: all 0.3s ease;
}

.our-properties .card:hover {
    box-shadow: 0px 5px 39px -14px rgba(0, 0, 0, 0.26);
}

.our-properties .card:hover img {
    transform: scale(1.1);
}

.our-properties .card .card-body .card-title a {
    text-decoration: none;
    color: var(--bs-dark);
}

.our-properties .card .propertie-card-img .badge {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 2px;
    background-color: var(--bs-success);
}

/* about-director */
.about-director {
    margin: 3rem 0;
}

.about-director img {
    width: 100%;
    object-fit: cover;
}

.about-director .btn {
    padding: 0.75rem 2rem;
    display: inline-flex;
    align-items: center;
    gap: 0.50rem;
    font-weight: 500;
    border-radius: 2px;
}

.about-director .btn i {
    font-weight: 600;
}

.about-counter {
    margin: 2rem 0;
}

.about-counter p {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: var(--bs-success);
    margin-bottom: 0;
}

.about-counter h2 {
    font-weight: 600;
}

/* cta-home */
.cta-home {
    padding: 3rem 0;
    background: linear-gradient(16deg, rgb(94 165 29 / 90%) 8%, rgb(94 165 29 / 90%) 100%), url("https://qa.vcqru.com/oxfordrealtors/assets/img/bg_4.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--bs-white);
}

.cta-home h2 {
    font-weight: 700;
}

.cta-home p {
    margin-bottom: 0;
}

.cta-home .btn {
    padding: 0.75rem 2rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    border-radius: 2px;
}

/* testimonial */
.testimonial {
    background-color: var(--bs-light);
    padding: 2rem 0;
}

.testimonial .heading-section {
    text-align: center;
}

.testimonial .card {
    border: 0;
    box-shadow: 0px 5px 12px -9px rgba(0, 0, 0, 0.17);
}

.testimonial .owl-carousel .owl-nav,
.testimonial .owl-carousel .owl-dots {
    display: none;
}

.testimonial .card .card-body .card-title {
    margin-bottom: 0;
}

.testimonial .card .card-body .card-text {
    color: var(--bs-secondary);
}

.testimonial .card .card-body .icon {
    width: 45px;
    height: 45px;
    border-radius: var(--bs-border-radius-pill);
    background: var(--bs-success);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 20px;
    margin-top: -40px;
    color: var(--bs-white);
}

.testimonial .owl-carousel .owl-stage-outer {
    padding: 2rem 0;
}

/* footer */

.footer .upper-footer {
    background-color: #06112a;
    padding: 3rem 0 2rem 0;
}

.footer .lower-footer {
    background-color: #050d21;
    padding: 2rem 0;
}

.footer .lower-footer p {
    color: var(--bs-secondary);
    margin-bottom: 0;
    text-align: center;
}

.footer .lower-footer p a {
    color: var(--bs-white);
    transition: all 0.3s ease;
}

.footer .lower-footer p a:hover {
    color: var(--bs-secondary);
}

.footer .about-footer img {
    height: 4rem;
    margin-bottom: 1rem;
}

.footer .about-footer a {
    gap: 0.25rem;
    color: var(--bs-white);
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.footer .about-footer p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    margin-bottom: 0.50rem;
    -webkit-box-orient: vertical;
    color: var(--bs-secondary);
}

.footer .about-footer ul.footer-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.50rem;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    margin-top: 1rem;
}

.footer .about-footer .footer-icons li a {
    height: 40px;
    width: 40px;
    background: var(--bs-success);
    border-radius: var(--bs-border-radius-pill);
    justify-content: center;
    border: 1px solid var(--bs-success);
    transition: all 0.3s ease;
}

.footer .about-footer .footer-icons li a:hover {
    color: var(--bs-success);
    background-color: var(--bs-white);
}

.footer .about-footer .footer-icons li a i {
    font-size: 20px;
}

.footer .footer-links h5 {
    color: var(--bs-white);
    margin-bottom: 1.50rem;
}

.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.50rem;
}

.footer .footer-links ul li a {
    text-decoration: none;
    color: var(--bs-secondary);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.footer .footer-links ul li a:hover {
    color: var(--bs-white);
}

.footer .row .col:last-of-type ul {
    color: var(--bs-secondary);
}

.footer .row .col:last-of-type ul li {
    display: flex;
    align-items: center;
    gap: 0.50rem;
}

.footer .row .col:last-of-type ul li p {
    margin-bottom: 0;
}

/* app-breadcrumb */
.app-breadcrumb {
    padding: 5rem 0 6rem 0;
    background: linear-gradient(16deg, rgb(0 0 0 / 30%) 8%, rgb(0 0 0 / 30%) 100%), url('https://qa.vcqru.com/oxfordrealtors/assets/img/bg_4.jpg');
    color: var(--bs-white);
}

.app-breadcrumb .breadcrumb {
    justify-content: center;
    color: var(--bs-white);
}

.app-breadcrumb .breadcrumb .breadcrumb-item a {
    color: var(--bs-white);
}

.app-breadcrumb .breadcrumb .breadcrumb-item a,
.blog-details .breadcrumb .breadcrumb-item a {
    text-decoration: none;
}

.blog-details .breadcrumb .breadcrumb-item a {
    color: var(--bs-dark);
}

.app-breadcrumb .breadcrumb .breadcrumb-item.active,
.blog-details .breadcrumb .breadcrumb-item.active {
    color: var(--bs-success);
}

.app-breadcrumb .breadcrumb .breadcrumb-item,
.blog-details .breadcrumb .breadcrumb-item {
    font-weight: 600;
    text-transform: capitalize;
}

.app-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: var(--bs-white);
}

.app-breadcrumb h1 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
    text-transform: capitalize;
}

/* about-section */
.about-section {
    margin: 2rem 0;
}

.about-section img {
    width: 100%;
}

/* contact-us */
.contact-us {
    padding: 3rem 0;
    background-color: var(--bs-light);
}

.contact-us .list-group .list-group-item {
    display: flex;
    align-items: start;
    gap: 1rem;
    background: transparent;
    padding: 1rem 0;
}

.contact-us .list-group .list-group-item p {
    color: var(--bs-secondary);
    margin-bottom: 0;
}

.contact-us .list-group .list-group-item img {
    height: 2rem;
}

.contact-us .list-group .list-group-item a {
    text-decoration: none;
    color: var(--bs-secondary);
}

.enquiry-form .card {
    border: 0;
    box-shadow: var(--bs-box-shadow-sm);
    border-radius: 0;
}

.enquiry-form .card .card-body .card-title {
    margin-bottom: 0;
    font-weight: 600;
}

.enquiry-form .card .card-body {
    padding: 1.50rem;
}

.form-group .form-label {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
}


.form-group .btn {
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0;
}

.blog-page {
    padding: 3rem 0;
    background-color: var(--bs-light);
}

.blog-page .card .card-body .card-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blog-page .card .card-body .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--bs-secondary);
}

/* blog-details */
.blog-details {
    padding: 2rem 0;
    background-color: var(--bs-light);
}

.blog-details .card {
    border: 0;
    box-shadow: var(--bs-box-shadow-sm);
}

.blog-details .card .card-body {
    padding: 2rem;
}

.blog-details .card .card-body img {
    width: 100%;
    border-radius: var(--bs-border-radius-lg);
    margin: 1rem 0;
}

.blog-details .card .card-body span {
    font-style: italic;
    color: var(--bs-success);
    font-weight: 500;
}

.blog-details .card .card-body .card-text,
.blog-details .card .card-body p,
.blog-details .card .card-body ul {
    color: var(--bs-secondary);
}

.blog-details .card .card-body .list-group .list-group-item {
    color: var(--bs-secondary);
    padding-left: 0;
    padding-right: 0;
}

/* properties-details */
.properties-header .container-fluid {
    padding: 0;
}

.properties-details .enquiry-form {
    position: sticky;
    top: 5rem;
}

.properties-header .owl-carousel .owl-dots {
    display: none;
}

.properties-header .owl-carousel .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 0;
    padding: 0 1rem;
    margin: 0;
}

.properties-header .owl-carousel .owl-nav .owl-prev,
.properties-header .owl-carousel .owl-nav .owl-next {
    height: 3rem;
    width: 3rem;
    border-radius: var(--bs-border-radius-pill);
    background-color: var(--bs-white);
    border: 1px solid var(--bs-border-color);
    color: var(--bs-success);
    font-size: 28px;
    font-weight: 600;
    box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.35);
}

/* properties-details */
.properties-details {
    margin: 2rem 0;
}

.properties-details .propertie-heading .properties-action {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.properties-details .propertie-heading .properties-action li .btn {
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
}

.properties-details .propertie-heading .properties-action li:last-of-type .btn {
    border: 2px solid var(--bs-border-color);
}

.properties-details .propertie-heading p {
    color: var(--bs-secondary);
}

.properties-details .propertie-heading {
    margin-bottom: 1.50rem;
}

/* properties-details-tabs */
.properties-details .properties-details-tabs .nav {
    gap: 0.50rem;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
    overflow-x: scroll;
    position: sticky;
    top: 4.60rem;
    background-color: var(--bs-light);
    padding: 1rem;
}

.properties-details .properties-details-tabs .nav::-webkit-scrollbar {
    display: none;
}

.properties-details .properties-details-tabs .nav .nav-item .nav-link {
    text-transform: capitalize;
    font-weight: 500;
    color: var(--bs-secondary);
    background-color: var(--bs-white);
    border-bottom: 0.16rem solid var(--bs-white);
    border-radius: 0;
    text-wrap: nowrap;
}

.properties-details .properties-details-tabs .nav .nav-item .nav-link.active {
    border-bottom: 0.16rem solid var(--bs-primary);
    color: var(--bs-primary);
}

.properties-details .properties-details-tabs .scrollspy .tab-pane {
    margin-bottom: 2rem;
}

.properties-details .properties-details-tabs .scrollspy .tab-pane:last-of-type {
    margin-bottom: 0;
}

.properties-details .properties-details-tabs .scrollspy .tab-pane p,
.properties-details .properties-details-tabs .scrollspy .tab-pane li {
    color: var(--bs-secondary);
}

.properties-details .properties-details-tabs .scrollspy .tab-pane li:last-of-type {
    margin-bottom: 0;
}

.properties-details .properties-details-tabs .scrollspy .tab-pane li {
    margin-bottom: 0.50rem;
}

/* admin-login */
.admin-login {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--bs-light);
}

.admin-login .card .card-body .admin-logo {
    text-align: center;
}

.admin-login .card {
    border: 0;
    border-radius: 0;
    box-shadow: var(--bs-box-shadow-sm);
}

.admin-login .card .card-body {
    padding: 2rem;
}

.admin-login .card .card-body .admin-logo img {
    height: 6rem;
}

.admin-login .card .card-body .card-title {
    text-transform: capitalize;
}

.admin-login .card .card-body .card-text {
    color: var(--bs-secondary);
}

.admin-login .card .card-body .form-group .btn {
    width: 100%;
}

/* add-property */
.add-property {
    background-color: var(--bs-light);
}

/* side-bar */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    padding: 10px;
    background: var(--bs-white);
    z-index: 100;
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
    box-shadow: 0 .125rem .375rem #22303e14;
}

.sidebar.close {
    width: 70px;
}

.sidebar.close:hover {
    width: 250px;
}

.sidebar .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    min-height: 100%;
}

.sidebar .menu {
    margin-top: 50px;
}

.sidebar .menu-bar .menu-links {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

.sidebar .menu-bar .menu .menu-links .nav-link {
    height: 45px;
    margin-top: 0.50rem;
}

.sidebar .icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    min-width: 50px;
    border-radius: var(--bs-border-radius-sm);
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item {
    border: 0;
    margin-top: 0.50rem;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: var(--bs-primary);
    color: var(--bs-white);
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button,
.sidebar .menu-bar .bottom-content .nav-link a,
.sidebar .menu-bar .menu .menu-links .nav-link a {
    font-weight: 500;
    white-space: nowrap;
    color: #707070;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    text-decoration: none;
    transition: all 0.2s;
    text-transform: capitalize;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button {
    padding: 0;
    background-color: var(--bs-white);
    box-shadow: none;
    height: 45px;
    border-radius: 2px;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) .icon,
.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) .text,
.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    color: var(--bs-white);
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-body {
    padding: 0;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-body ul {
    padding: 0;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-body ul li {
    margin: 0.25rem 0;
    height: 40px;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-body ul li a {
    font-size: 14px;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-body ul li a:hover {
    background-color: var(--bs-primary-bg-subtle);
    color: var(--bs-primary);
}

.sidebar .menu-bar::-webkit-scrollbar {
    display: none;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header:hover .accordion-button::after {
    color: var(--bs-white);
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button::after {
    content: "\f078";
    font-family: Font Awesome\ 5 Free;
    font-size: 16px;
    font-weight: 900;
    background: none;
    margin-right: 8px;
    color: #707070;
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:hover,
.sidebar .menu-bar .menu .menu-links .nav-link a:hover,
.sidebar .menu-bar .bottom-content .nav-link a:hover {
    background-color: var(--bs-primary);
    box-shadow: var(--bs-box-shadow-sm);
    color: var(--bs-white);
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:hover .icon,
.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:hover .text {
    color: var(--bs-white);
}

.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:hover .icon,
.sidebar .menu-bar .menu .menu-links .menu-dropdown .accordion .accordion-item .accordion-header .accordion-button:hover .text {
    color: var(--bs-white);
}

.sidebar .menu-bar .bottom-content {
    position: sticky;
    bottom: 0;
    background-color: var(--bs-white);
    z-index: 100;
}

.sidebar .menu-bar .bottom-content .nav-link {
    height: 45px;
    list-style: none;
}

/* nav-layout */
.nav-layout {
    border-bottom: 1px solid #F2F2F7;
    background-color: var(--bs-white);
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: var(--bs-box-shadow-sm);
}

.nav-layout .navbar {
    padding: 0;
}

.nav-layout .navbar .navbar-brand img {
    height: 3rem;
}

/* page-wrapper */
.page-wrapper {
    margin-left: 250px;
    overflow: hidden;
}

.page-wrapper .home-section {
    padding: 1.50rem;
}

/* admin cards */
.page-wrapper .home-section .admin-head-title {
    margin-bottom: 1rem;
    text-transform: capitalize;
}

.page-wrapper .home-section .card {
    box-shadow: var(--bs-box-shadow-sm);
    border-color: var(--bs-border-color);
}

.page-wrapper .home-section .top-dashboard-card {
    margin-bottom: 1rem;
}

.page-wrapper .home-section .top-dashboard-card .card .card-body .card-title {
    text-transform: capitalize;
}

.page-wrapper .home-section .top-dashboard-card .card .card-body h3 {
    margin-bottom: 0;
}

/* .page-wrapper .home-section .recent-enquiry .table {
    margin-bottom: 0;
} */